<template>
  <div class="domains-order">
    <page-block :title="$t('title')">
      <main-card class="domains-order__card">
        <template #header>
          <p class="standart-text">
            {{ $t('desc') }}
          </p>
        </template>
        <div class="domains-order__form">
          <div class="domains-order__check">
            <base-input
              v-model.trim="domain"
              :invalid="isInvalidDomain"
              :label="$t('check.label')"
              name="domain"
              class="domains-order__check-input"
              @keyup.enter="checkDomain"
            />
            <base-button class="domains-order__check-action" @click="checkDomain">
              {{ $t('check.action') }}
            </base-button>
          </div>
          <div class="domains-order__pricelist">
            <div class="l-flex-2_sm-4_lg-5">
              <div v-for="item in pricelist" :key="item.id" class="l-col">
                <base-checkbox
                  v-model="zones"
                  :value="item.id"
                  class="domains-order__pricelist-item"
                >
                  <div class="domains-order__pricelist-label">
                    {{ '.' + item.zone }}
                    <div class="domains-order__pricelist-cost">
                      {{ $n(item.cost, 'currency') }}
                    </div>
                  </div>
                </base-checkbox>
              </div>
            </div>
          </div>
        </div>
        <template #footer>
          <div class="domains-order__results">
            <transition name="fade" mode="out-in">
              <base-loader v-if="isChecking" />
              <base-alert v-else-if="checkError" :title="checkError" />
              <div
                v-else-if="domain && mappedList.length && !filteredList.length"
                class="domains-order__no-res"
              >
                <p class="standart-text">
                  {{ $t('noresults') }}
                </p>
              </div>
              <domains-table-check
                v-else-if="domain"
                :list="filteredList"
                :domain="domain"
                @order="onOrder"
              />
            </transition>
          </div>
        </template>
      </main-card>
    </page-block>
  </div>
</template>

<script>
import DomainsTableCheck from '../../../components/DomainsTableCheck.vue';
import MainCard from '@/components/MainCard/MainCard.vue';
import BaseInput from '@/components/BaseInput/BaseInput.vue';
import BaseCheckbox from '@/components/Checkboxes/BaseCheckbox.vue';
import BaseAlert from '@/components/BaseAlert/BaseAlert.vue';
import BaseBasket from '@/components/BaseBasket/BaseBasket.vue';
import BaseBasketSummary from '@/components/BaseBasket/BaseBasketSummary.vue';
import showErrorModal from '@/mixins/showErrorModal';
import wizardPay from '@/mixins/billmgr/wizardPay';
import wizard from '@/mixins/billmgr/wizard';
import handleRedirect from '@/mixins/billing/handleRedirect';
import updateBalance from '@/mixins/billing/updateBalance';
import { BasketItem } from '@/models/BillMgr/Basket';
import qs from 'qs';
import billMgrToolActions from '@/mixins/billmgr/billMgrToolActions';
import PaymentMethodConfigurator from '@/models/BillMgr/PaymentMethodConfigurator';
import BaseConfigurator from '@/models/base/BaseConfigurator';
export default {
  name: 'DomainsOrder',
  components: {
    DomainsTableCheck,
    MainCard,
    BaseInput,
    BaseCheckbox,
    BaseAlert,
  },
  mixins: [showErrorModal, wizardPay, wizard, billMgrToolActions, handleRedirect, updateBalance],
  data() {
    return {
      domain: '',
      domainHasError: false,
      zones: [],
      list: [],
      isChecking: false,
      checkError: '',
      startFunc: 'domain.order.name',
      startAction: 'sendBillMgrAction',
      startStep: 0,
      confirmationModal: null,
    };
  },
  computed: {
    pricelist() {
      return this.$store.state.moduleDomains.moduleDomainsOrder.list;
    },
    isInvalidDomain() {
      return this.domain.length < 2 && this.domainHasError;
    },
    mappedList() {
      return this.list.map(i => {
        // console.log(i);
        const pricelistItem = this.pricelist.find(p => p.id === i.id);
        // console.log(pricelistItem);
        let periodType = i.price.period[0].type;
        let periodLen = i.price.period[0].length;
        if (periodType === 'month' && periodLen % 12 === 0) {
          periodType = 'year';
          periodLen = periodLen / 12;
        }
        return {
          id: i.id,
          cost: i.price.period[0].cost,
          period: i.price.period[0].length,
          periodLen,
          periodType,
          zone: pricelistItem.zone ? pricelistItem.zone : null,
          // name: this.domain + '.' + pricelistItem.zone,
          name: this.domain.includes(pricelistItem.zone)
            ? this.domain.toLowerCase()
            : this.domain.toLowerCase() + '.' + pricelistItem.zone,
          status: i.status,
        };
      });
    },
    filteredList() {
      if (!this.zones.length) return this.mappedList;
      return this.mappedList.filter(i => this.zones.includes(i.id));
    },
    model() {
      return this.$store.state.moduleDomains.moduleDomainsOrder.model;
    },
  },
  watch: {
    domain() {
      this.reset();
    },
  },
  mounted() {
    this.fetchList();
    this.fetchPricelist();
  },
  methods: {
    fetchPricelist() {
      this.$store.dispatch('moduleDomains/moduleDomainsOrder/fetchPricelist');
    },
    fetchList() {
      this.$store.dispatch('moduleDomains/moduleDomainsDomains/fetchList');
    },
    checkDomain() {
      this.domainHasError = !this.domain;
      this.reset();
      if (this.domainHasError) return;
      const params = {
        domain: this.domain.toLowerCase(),
        newface: 'on',
      };
      this.isChecking = true;
      this.$store
        .dispatch('moduleDomains/moduleDomainsOrder/checkDomain', params)
        .then(data => {
          if (data && data.pricelist) this.list = [...data.pricelist];
        })
        .catch(e => {
          // this.checkError = e.msg;
          this.checkError = this.$t('modal.res.error', { domain: this.domain.split('.')[1] });
        })
        .finally(() => (this.isChecking = false));
    },
    onOrder(item) {
      this.setOrderWizardParams();
      this.startParams = {
        ...this.model,
        selected_domain: item.name,
        checked_domain: `${item.name}:1:${item.id}:1`,
        domain_name: item.name,
        selected_pricelist: item.id,
        domain_action: 'register',
        snext: 'ok',
        newface: 'on',
      };
      this.runWizard()
        .then(data => {
          const { billorder } = qs.parse(data.ok.v);
          const list = data.itemdesc.map(item => this.getBasketItem(item));
          const orderSum = list.reduce((sum, item) => {
            return (sum += item.cost);
          }, 0);
          this.finishOrder(list, orderSum, billorder);
        })
        .catch(() => {});
    },
    finishOrder(list, orderSum, billorder) {
      this.showConfirmationModal(list, orderSum, { editable: false, deletable: false })
        .then(res => {
          if (res === 'pay') {
            this.setPaymentWizardParams();
            this.startParams = { billorder };
            this.runWizardPay()
              // this.runWizard()
              .then(async data => {
                const res = await this.handleRedirect(data.ok);
                if (res.ok) {
                  const text =
                    res.func === 'redirect'
                      ? this.$t('success.redirect')
                      : this.$t('success.pay', { num: this.$n(orderSum, 'currency') });
                  this.showSuccessModal(text);
                }
                this.updateBalance();
                this.setOrderWizardParams();
              })
              .catch(e => {
                if (e && e === 'cancel') this.finishOrder(list, orderSum, billorder);
                else this.deleteOrderFromBasket(list);
              });
          } else {
            const promises = [];
            list.forEach(item => {
              promises.push(
                this.sendBillMgrAction({
                  billorderitem: item.id,
                  func: 'rollback.billorderitem',
                  newface: 'on',
                })
              );
            });
            Promise.allSettled(promises).finally(() => {
              this.showSuccessModal(
                this.$t('success.basket', { num: this.$n(orderSum, 'currency') })
              );
              this.$store.dispatch('moduleBasket/fetchBasket');
            });
          }
          if (this.confirmationModal) {
            this.$modals.close({ name: this.confirmationModal.name });
          }
        })
        .catch(() => this.deleteOrderFromBasket(list));
    },
    setOrderWizardParams() {
      this.startFunc = 'domain.order.name';
      this.startAction = 'sendBillMgrAction';
      this.startStep = 0;
      this.endParams = { newbasket: 'on' };
      this.actionsTitles.confirm.end = this.$t('continue');
      this.showResFunc.success = false;
      this.configuratorClass = BaseConfigurator;
    },
    setPaymentWizardParams() {
      this.startFunc = 'payment.add';
      this.startAction = 'fetchBillMgrAction';
      this.endParams = null;
      this.actionsTitles.confirm.end = this.$t('submit');
      // this.actionsTitles.confirm.end = this.$t('pay');
      // this.actionsTitles.confirm.end = this.$t('confirm');
      this.showResFunc.success = false;
      this.configuratorClass = PaymentMethodConfigurator;
    },
    showConfirmationModal(list, total, props = {}) {
      return new Promise((resolve, reject) => {
        this.$modals.open({
          name: 'ConfirmationModal',
          component: BaseBasket,
          props: {
            list,
            ...props,
          },
          onOpen: inst => (this.confirmationModal = inst),
          onClose: () => (this.confirmationModal = null),
          onDismiss: () => reject(),
          footer: {
            confirm: {
              props: { title: this.$t('order.pay') },
              on: {
                click: () => resolve('pay'),
              },
            },
            cancel: {
              props: { title: this.$t('order.basket') },
              on: {
                click: () => resolve('basket'),
              },
            },
            top: {
              component: BaseBasketSummary,
              props: { total },
              separate: 'top',
            },
          },
        });
      });
    },
    showSuccessModal(text) {
      this.$modals.open({
        name: 'SuccessModal',
        title: this.$t('order.success.title'),
        text,
        footer: {
          centered: true,
          confirm: {
            props: { title: this.$t('order.ok') },
            on: {
              click: () => this.$modals.close(),
            },
          },
        },
      });
    },
    reset() {
      this.list = [];
      this.checkError = '';
    },
    getBasketItem(item) {
      const args = {
        ...item,
        id: item['billorderitem.id'],
        cost: item.total_cost,
        'item.period': '12',
        'item.type': 'domain',
        pricelist_name: item.main.name,
      };
      return new BasketItem(args);
    },
    deleteOrderFromBasket(list) {
      const promises = [];
      list.forEach(item => {
        promises.push(
          this.sendBillMgrAction({
            id: item.id,
            func: 'basket',
            clicked_button: 'delete',
          })
        );
      });
      Promise.all(promises).finally(() => {
        this.$store.dispatch('moduleBasket/fetchBasket');
      });
      this.setOrderWizardParams();
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "title": "Регистрация домена",
    "desc": "Введите желаемое доменное имя или любое слово",
    "check": {
      "label": "Имя домена",
      "action": "Подобрать домен"
    },
    "noresults": "Элементов не найдено",
    "order": {
      "next": "Дальше",
      "confirm": "Заказать",
      "ok": "Хорошо",
      "pay": "Оплатить",
      "basket": "В корзину",
      "success": {
        "title": "Успешно",
        "basket": "Заказ отправлен в корзину"
      }
    },
    "modal": {
      "res": {
        "success": "Запрос выполняется. Пожалуйста, дождитесь обновления данных",
        "error": "Выбранная вами доменная зона .{domain} недоступна для регистрации.",
        "fail": "Кажется, что-то пошло не так. Пожалуйста, попробуйте позже."
      }
    },
    "success": {
      "basket": "Заказ на {num} успешно добавлен в корзину",
      "pay": "Заказ оплачен, {num} списано с лицевого счета",
      "redirect": "Продолжите оплату и дождитесь обновления данных"
    }
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.domains-order {
  &__card {
    max-width: 830px;
  }
  &__check {
    +breakpoint(sm-and-up) {
      flexy(flex-start, flex-end);
    }
    &__input {
      +breakpoint(sm-and-up) {
        max-width: 16.25rem;
      }
    }
    &-action {
      margin-top: 1.5rem;
      +breakpoint(sm-and-up) {
        margin-top 0;
        margin-left: 1.5rem;
      }
    }
  }
  &__pricelist {
    margin-top: 1.5rem;

    +breakpoint(sm-and-up) {
      margin-top: 2.5rem;
    }

    &-item {
      max-width: 174px;
    }

    >>> .base-checkbox__label-text {
      width: 100%;
      max-width: 104px;
    }

    &-label {
      flexy(space-between, baseline)
    }
    &-cost {
      margin-left: 0.25rem;
    }
  }
  &__no-res {
    padding-bottom: $gutter-vertical-ms;
    +breakpoint(sm-and-up) {
      padding-bottom: $gutter-vertical;
    }
  }
}
</style>
